import React from 'react';
import Slider from 'react-slick';

import loadable from '@loadable/component';

import awardImgPng01 from '../../../assets/images/products/award-img-01.png';
import awardImgWebp01 from '../../../assets/images/products/award-img-01.png?=webp';
import awardImgPng02 from '../../../assets/images/products/award-img-02.png';
import awardImgWebp02 from '../../../assets/images/products/award-img-02.png?=webp';
import awardImgPng03 from '../../../assets/images/products/award-img-03.png';
import awardImgWebp03 from '../../../assets/images/products/award-img-03.png?=webp';

import ProductCCPng from '../../../assets/images/products/CC-product.png';
import ProductCCWebp from '../../../assets/images/products/CC-product.png?=webp';

const ImageLoader = loadable(() => import('../common/ImageLoader'));

const awardsImages = [
  {
    id: 'Best-Air-Purifier-for-Pets',
    baseUrl: awardImgPng01,
    webpUrl: awardImgWebp01,
    alt: 'Best Air Purifier for Pets',
    width: 178,
    height: 62,
  },
  {
    id: 'Best-Air-Purifier-for-Pet-Odor',
    baseUrl: awardImgPng02,
    webpUrl: awardImgWebp02,
    alt: 'Best Air Purifier for Pet Odor',
    width: 213,
    height: 62,
  },
  {
    id: 'Best-Air-Purifier-for-Pets',
    baseUrl: awardImgPng03,
    webpUrl: awardImgWebp03,
    alt: 'Best Air Purifier for Pets',
    width: 178,
    height: 62,
  },
];

const AwardSlider = ({ className }) => {
  const Settings = {
    infinite: false,
    arrows: false,
    dots: false,
    variableWidth: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0',
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        },
      },
    ],
  };

  return (
    <Slider className={`product-awards ${className}`} {...Settings}>
      {awardsImages.map(({
        id, baseUrl, webpUrl, alt, height, width,
      }) => (
        <div className='product-awards__item' key={id}>
          <ImageLoader
            className='product-awards__item-image img-relative'
            placeholderAspectRatio={width / height}
            image={baseUrl}
            webp={webpUrl}
            alt={alt}
          />
        </div>
      ))}
    </Slider>
  );
};

const ProductHero = () => (
  <section className='product-hero'>
    <div className='product-hero__card'>
      <div className='container'>
        <AwardSlider className='for-small-screen' />
        <div className='product-hero__grid'>
          <div className='product-hero__content'>
            <AwardSlider className='for-large-screen' />
            <h1 className='product-hero__title'>
              Take the animal
              <br />
              {' '}
              out of your air.
            </h1>
            <div className='product-hero__description'>
              <p className='above-tablet-screen'>
                Clear the air of dander, allergens, fur and
                <br />
                more with Mila – the award-winning air purifier winning hearts
                (and lungs) everywhere.
              </p>
              <p className='below-tablet-screen'>
                Clear the air of dander, allergens, fur and more with Mila.
              </p>
            </div>
          </div>
          <div className='product-hero__media'>
            <ImageLoader
              className='product-hero__image img-relative'
              placeholderAspectRatio={717 / 562}
              image={ProductCCPng}
              webp={ProductCCWebp}
              alt='Critter Cuddler Mila Filter'
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ProductHero;
